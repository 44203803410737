import { ServiceComponent } from '../components/ServiceComponent';
import { Slider } from '../components/Slider';


const Servicepage = () =>{

    return (
        <>
            <Slider />
            <ServiceComponent />
        </>
    )
}

export {Servicepage};